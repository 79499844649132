<template>
  <section id="Team" class="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:px-8">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="text-center">
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">About Me</h2>
        <p class="mt-6 text-lg leading-8 text-gray-600">Providing innovative and reliable full-stack solutions to empower your business.</p>
      </div>
      <div class="mt-16 flex flex-col items-center">
        <div class="flex flex-col items-center sm:flex-row sm:items-center gap-x-6">
          <div class="overflow-hidden rounded-full h-32 w-32 mb-6 sm:mb-0">
            <img class="h-full w-full object-cover transform scale-125" src="../assets/me.jpg" alt="Photo of Vishal Ramanlal" />
          </div>
          <div class="text-center sm:text-left">
            <h3 class="text-2xl font-semibold leading-7 tracking-tight text-gray-900">Vishal Ramanlal</h3>
            <p class="text-lg font-semibold leading-6 text-indigo-600">Founder & Full-Stack Developer</p>
            <p class="mt-4 text-base leading-7 text-gray-600">With 5 years of experience in developing full-stack solutions, I have a passion for creating innovative and reliable software that empowers businesses to achieve their goals.</p>
          </div>
        </div>
      </div>
      <ul v-if="people.length > 0" role="list" class="mt-16 grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
        <li v-for="person in people" :key="person.name" class="flex items-center gap-x-6">
          <img class="h-16 w-16 rounded-full" :src="person.imageUrl" :alt="'Photo of ' + person.name" />
          <div>
            <h3 class="text-base font-semibold leading-7 tracking-tight text-gray-900">{{ person.name }}</h3>
            <p class="text-sm font-semibold leading-6 text-indigo-600">{{ person.role }}</p>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script setup>
const people = [
  // Future team members can be added here
]
</script>

<style scoped>

</style>

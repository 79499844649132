<template>
  <header :class="['fixed inset-x-0 top-0 z-50 transition-colors duration-300', { 'bg-slate-900 bg-opacity-90 text-white': scrolled, 'bg-transparent text-white': !scrolled }]">
    <nav class="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
      <div class="flex lg:flex-1">
        <a href="#" class="-m-1.5 p-1.5 hidden lg:inline">
          <span class="sr-only">Innovate With V</span>
          <img :src="scrolled ? '/assets/favcon.png' : '/assets/favcon.png'" :alt="scrolled ? 'Innovate With V - Scrolled' : 'Innovate With V'" class="h-8 w-auto transition-all duration-300" />
        </a>
      </div>
      <div class="flex lg:hidden" v-show="scrolled || mobileMenuOpen">
        <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = true" aria-expanded="false" aria-controls="mobile-menu">
          <span class="sr-only">Open Menu</span>
          <Bars3Icon class="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div class="hidden lg:flex lg:gap-x-12">
        <a v-for="item in navigation" :key="item.name" :href="item.href" class="text-sm font-semibold leading-6 text-gray-100 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500">{{ item.name }}</a>
      </div>
    </nav>
    <Dialog id="mobile-menu" class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen" aria-label="Mobile menu">
      <div class="fixed inset-0 z-50" aria-hidden="true"></div>
      <DialogPanel class="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
        <div class="flex items-center justify-between">
          <a href="#" class="-m-1.5 p-1.5">
            <span class="sr-only">Innovate With V</span>
            <img class="h-8 w-auto" src="/assets/favcon.png" alt="Innovate With V" />
          </a>
          <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = false">
            <span class="sr-only">Close menu</span>
            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div class="mt-6 flow-root">
          <div class="-my-6 divide-y divide-gray-500/10">
            <div class="space-y-2 py-6">
              <a v-for="item in navigation" :key="item.name" :href="item.href" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500">{{ item.name }}</a>
            </div>
            <div class="py-6">
              <a href="#" class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500">Log in</a>
            </div>
          </div>
        </div>
      </DialogPanel>
    </Dialog>
  </header>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import { Dialog, DialogPanel } from '@headlessui/vue'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'

const navigation = [
  { name: 'Home', href: '#' },
  { name: 'About', href: '#About' },
  { name: 'Me', href: '#Team' },
  { name: 'Contact us', href: '#Contact' },
]

const mobileMenuOpen = ref(false)
const scrolled = ref(false)

const handleScroll = () => {
  scrolled.value = window.scrollY > 100
}

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>


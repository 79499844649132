<template>
  <div>
    <NavigationBar />
    <HeroSection />
    <TestimonialsSection />
    <AboutSection />
    <TeamSection />
    <ActionSection />
    <ContactFormSection />
    <SiteFooter />
  </div>
</template>

<script>
import NavigationBar from './components/NavigationBar.vue'
import HeroSection from './components/HeroSection.vue'
import SiteFooter from './components/SiteFooter.vue'
import AboutSection from './components/AboutSection.vue'
import TestimonialsSection from './components/TestimonialsSection.vue'
import ActionSection from './components/ActionSection.vue'
import ContactFormSection from './components/ContactFormSection.vue'
import TeamSection from './components/TeamSection.vue'

export default {
  name: 'App',
  components: {
    NavigationBar,
    HeroSection,
    SiteFooter,
    AboutSection,
    TestimonialsSection,
    ActionSection,
    ContactFormSection,
    TeamSection,
  }
}
</script>

<style>
</style>

<template>
  <section class="text-gray-600 body-font bg-slate-900 h-screen flex items-center">
    <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
      <div class="lg:flex-grow flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center order-2 md:order-1">
        <h1 class="text-2xl font-extrabold leading-9 tracking-tight mb-3 text-gray-100 sm:text-4xl sm:leading-10 md:text-5xl md:leading-normal hidden md:block">
          Innovate With V
        </h1>
        <p class="mb-8 md:pl-0 pl-2 pr-2 leading-relaxed text-gray-300">
          Your local website and storefront building specialists
        </p>
        <div class="flex justify-center">
          <a href="#Contact" class="inline-flex text-white bg-indigo-600 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-800 rounded text-lg" aria-label="Get Started">
            Get Started
          </a>
          <a href="#About" class="ml-4 inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg" aria-label="Learn More">
            Learn More
          </a>
        </div>
      </div>
      <div class="lg:max-w-lg lg:w-full md:w-1/2 w-full flex justify-center lg:justify-end order-1 md:order-2">
        <img class="object-cover object-center rounded" alt="Innovate With V" src="/assets/logo4.png">
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HeroSection',
}
</script>

<script setup>
</script>


